.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure popup content is above overlay */
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 2; /* Ensure overlay is below popup content */
  }
  
  .popup-content {
    background-color: white;
    width: 400px; /* Set the width to create a square frame */
    height: 400px; /* Set the height to match the width */
    padding: 20px;
    border-radius: 10px;
    font-size: 24px; /* Adjust font size as needed */
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .popup-content.visible {
    opacity: 1;
  }
  .ac-icons-container {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px; /* Add space between the icons */
  }
  
  .ac-icon {
    width: 90px; /* Adjust the width as needed */
    height: 80px; /* Adjust the height as needed */
  }
  
  .text{
    margin-top: 20px;
    align-items: center;
  }
  .ac-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ac-icon-wrapper span {
    margin-top: 5px; /* Adjust the margin as needed */
  }
  