@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Roboto'; */
}

ul,
p {
  margin: 0;
  padding: 0;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
  transition: none !important;
}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  padding: 0.5rem;
  box-sizing: border-box;
}
/* 
@media screen and (min-width: 15000px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 50% !important;
  }
}


@media screen and (min-width: 2560px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}


@media screen and (min-width: 1440px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
} */
/*
@media screen and (min-width: 1024px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
} 
*/