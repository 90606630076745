.service-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    min-height: 100vh;
    /* margin-top: 80px; */
  }
  
  .service-options {
    border: 1px solid #ccc;
    padding: 40px;
    border-radius: 5px;
    margin: 20px;
    background-color: white;
    width: 70%;
    max-width: 800px;
    margin-top: 40px;
  }
  
  .service-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 999;
   
    
  }
  .icon-box :first-child {
    margin: 10px;
    margin-right: 30px;
  }
  
  .icon-box:last-child {
    margin-right: 0; /* Remove right margin for the last icon */
  }
  
  .slider-container {
    width: 70%;
    margin: 20px ;
    margin-top: 100px;
  }
  .a{
    align-items: center;
    /* margin-left: 10px; */
    margin-left: 25px;
  }
  .a1{
    align-items: center;
    margin-left: 25px;
  }
  .a2{
    align-items: center;
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    .service-container {
      flex-direction: column;
      margin-top: 110px;
    }
  
    .service-options {
      width: 70%;
    }
  
    .slider-container {
      width: 90%;
      margin: 20px ;
    }
    .service-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
  }
  .clickable-image {
  cursor: pointer; /* Change cursor to pointer */
}
