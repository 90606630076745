.css-1s2u09g-control{
    height: 100%;
    /* background-color: #f0f0f0 !important; */
    /* border: none !important; */
    /* border-color: #707070 !important; */
    border-radius: 5px !important;
    padding: 0.3rem 0 !important;
    /* border-bottom: 2px solid #0000002b !important; */
}
.css-1pahdxg-control{
    padding: 0.3rem 0 !important;
}
.css-b62m3t-container {
    /* height: 100%; */
}

.css-1pahdxg-control {
    height: 100%;
    border-color: hsl(0, 29%, 97%) !important;
}