@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.select-search .css-1s2u09g-control{
    height: 100%;
    /* background-color: #f0f0f0 !important; */
    /* border: none !important; */
    /* border-color: #707070 !important; */
    border-radius: 5px !important;
    padding: 0.45rem 0 !important;
    border: none !important;
    /* border-bottom: 2px solid #0000002b !important; */
}
.select-search .css-1pahdxg-control{
    padding: 0.45rem 0 !important;
    border: none !important;
    box-shadow: none !important;
}
.select-search .css-tlfecz-indicatorContainer{
    color: rgb(204, 204, 204);
    /* display: flex; */
    padding: 8px;
    display: none !important;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}
.select-search .css-1gtu0rj-indicatorContainer{
    color: rgb(204, 204, 204);
    display: none !important;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}
.css-b62m3t-container {
    /* height: 100%; */
}
.select-search .css-14el2xx-placeholder{
        color: hsl(0, 0%, 50%);
        grid-area: 1/1/2/3;
        margin-left: 2px;
        margin-right: 2px;
        padding: 0.28rem !important; 
        box-sizing: border-box;
}
.css-1pahdxg-control {
    height: 100%;
    border-color: hsl(0, 29%, 97%) !important;
}
.select-search .css-tj5bde-Svg{
    display: none !important;
}
.select-search .css-1okebmr-indicatorSeparator{
    display: none !important;
}
@media screen and (max-width: 600px) {
  .media {
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
  }
}

.css-1s2u09g-control{
    height: 100%;
    /* background-color: #f0f0f0 !important; */
    /* border: none !important; */
    /* border-color: #707070 !important; */
    border-radius: 5px !important;
    padding: 0.3rem 0 !important;
    /* border-bottom: 2px solid #0000002b !important; */
}
.css-1pahdxg-control{
    padding: 0.3rem 0 !important;
}
.css-b62m3t-container {
    /* height: 100%; */
}

.css-1pahdxg-control {
    height: 100%;
    border-color: hsl(0, 29%, 97%) !important;
}
.service-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: black;
    min-height: 100vh;
    /* margin-top: 80px; */
  }
  
  .service-options {
    border: 1px solid #ccc;
    padding: 40px;
    border-radius: 5px;
    margin: 20px;
    background-color: white;
    width: 70%;
    max-width: 800px;
    margin-top: 40px;
  }
  
  .service-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 999;
   
    
  }
  .icon-box :first-child {
    margin: 10px;
    margin-right: 30px;
  }
  
  .icon-box:last-child {
    margin-right: 0; /* Remove right margin for the last icon */
  }
  
  .slider-container {
    width: 70%;
    margin: 20px ;
    margin-top: 100px;
  }
  .a{
    align-items: center;
    /* margin-left: 10px; */
    margin-left: 25px;
  }
  .a1{
    align-items: center;
    margin-left: 25px;
  }
  .a2{
    align-items: center;
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    .service-container {
      flex-direction: column;
      margin-top: 110px;
    }
  
    .service-options {
      width: 70%;
    }
  
    .slider-container {
      width: 90%;
      margin: 20px ;
    }
    .service-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
  }
  .clickable-image {
  cursor: pointer; /* Change cursor to pointer */
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure popup content is above overlay */
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 2; /* Ensure overlay is below popup content */
  }
  
  .popup-content {
    background-color: white;
    width: 400px; /* Set the width to create a square frame */
    height: 400px; /* Set the height to match the width */
    padding: 20px;
    border-radius: 10px;
    font-size: 24px; /* Adjust font size as needed */
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .popup-content.visible {
    opacity: 1;
  }
  .ac-icons-container {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 70px;
    gap: 70px; /* Add space between the icons */
  }
  
  .ac-icon {
    width: 90px; /* Adjust the width as needed */
    height: 80px; /* Adjust the height as needed */
  }
  
  .text{
    margin-top: 20px;
    align-items: center;
  }
  .ac-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ac-icon-wrapper span {
    margin-top: 5px; /* Adjust the margin as needed */
  }
  
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Roboto'; */
}

ul,
p {
  margin: 0;
  padding: 0;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
  transition: none !important;
}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  padding: 0.5rem;
  box-sizing: border-box;
}
/* 
@media screen and (min-width: 15000px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 50% !important;
  }
}


@media screen and (min-width: 2560px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
}


@media screen and (min-width: 1440px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
} */
/*
@media screen and (min-width: 1024px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100% !important;
  }
} 
*/
/* @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Regular'), url('./fonts/Segoe\ UI.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('./fonts/Segoe\ UI\ Italic.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    src: local('Segoe UI Bold'), url('./fonts/Segoe\ UI\ Bold.woff') format('woff');
    }
    
  
    @font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 700;
    src: local('Segoe UI Bold Italic'), url('./fonts/Segoe\ UI\ Italic.woff') format('woff');
    }
  
  body {
    font-family: "Segoe UI", sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #545454;
    line-height: 1.4;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  } */
  a,
  a:hover {
    color: #49b1a7;
    text-decoration: none;
  }
  
  b, strong{
    color: #202020;
  }
  
  .btn-primary,
  .btn-primary:hover {
    background: #0f3f8c;
    border-color: #0f3f8c;
    color: #fff;
  }
  .banner {
    background: #f8f8f8;
    padding: 140px 0;
    /* background-image: url("../src/images/banner_image.png"); */
    background-repeat: no-repeat;
    background-position: right center;
    position: relative;
  }
  
  
  
  h1 {
    font-size: 40px;
    line-height: 60px;
    font-weight: bold;
  }
  h1,h2,h3,h4,h5,h6{
    color: #202020;
  }
  
  .banner strong {
    color: #49b1a7;
  }
  
  .banner h1 {
    max-width: 655px;
  }
  .product-filter {
    background: #f8f8f8;
    width: 320px;
    padding: 0 15px;
    
  }
  .react-slider__picture img {
    min-height: 300px;
    max-height: 300px;
  
    object-fit: contain;
    object-position: center center;
  }
  
  .product-filter h3 {
    font-size: 20px;
    margin: 0 0 15px;
    color: #202020;
  }
  
  .product-filter ul {
    padding: 0;
    margin: 0 0 30px;
    list-style: none;
  }
  
  .product-filter ul li {
    font-size: 14px;
    line-height: 21px;
    position: relative;
    padding-left: 22px;
    margin-bottom: 9px;
  }
  
  .product-filter ul li input {
    position: absolute;
    left: 0;
    top: 4px;
  }
  
  .product-section {
    display: flex;
    margin-top: 30px;
  }
  
  .product_section_content {
    width: calc(100% - 320px);
    padding: 0 30px;
  }
  p.post_no {
    padding-top: 5px;
  }
  .custom-tags .t-item img {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  .custom-tags .t-item{
    margin-right:10px;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
  }
  
  
  .product-item {
    width: calc(33.3% - 20px);
    margin: 0 10px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%);
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    
  }
  .profile_content .signup-cont .btn {
    width: 180px;
    margin-bottom: 10px;
  }
  .post-form.subscriptions.container {
    padding-top: 100px;
  }
  
  .feature-footer-left p {
    margin: 10px 0 0;
  }
  .feature-footer-left h3 + p {
    margin-top: 0;
  }
  .filter_btn .btn {
    background: #1f2449;
    border-color: 1f2449;
  }
  .products_items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  
  .feature_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
      object-position: center center;
  }
  
  .feature_content {
    padding: 10px 20px 0 20px;
    font-size: 14px;
  }
  
  .feature_content h3,.product-head h3 {
    color:#231f20;
    font-size: 19px;
    padding-right: 40px;
    font-weight:600;
    position: relative;
    text-transform: capitalize;
  }
  .product-item * {
    text-transform: capitalize;
  }
  .feature_content strong {
    font-weight: 500;
  }
  
  .feature-footer strong {
    font-weight: 500;
  }
  .feature_content h3 a,.product-head h3 a {
    width: 32px;
    height: 32px;
    position: absolute;
    background: #a6a6a6;
    color: #fff;
    line-height: 32px;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
    right: 0;
    top: 0;
  }
  
  .feature_content h4 {
    font-size: 14px;
    font-weight: 500;
  }
  
  .feature_image {
    width: 90px;
    height: 90px;
    min-height: 90px;
    border-radius: 5px;
    overflow: hidden;
    float: right;
    min-width: 90px;
  }
  .custom-tags .bg-tag {
    background: #e1e1e1;
    margin-right: 5px;
    display: inline-block;
    font-size: 12px;
    padding: 7px 10px;
    border-radius: 5px;
    color: #595a5a;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  span.cm-sector {
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;
    margin-left: 0;
    color: #49b1a8;
    font-weight: 600;
  
  }
  p.custom-tags.two_col > span:first-child {
    width: 66.6%;
  }
  p.custom-tags.two_col > span {
    width: 50%;
  }
  
  p.custom-tags.two_col {
    display: flex;
  }
  
  
  .feature-footer-left .text-lg {
    font-size: 16px;
  }
  .custom-tags .bg-tag span{
    color: #000;
    font-weight: bold;
  }
  .feature-footer-left h3 {
    color: #595a5a;
    font-size: 15px;
    margin: 0 0 5px;
  }
  
  .feature-footer-left p {
    color: #000;
    font-size: 11px;
    font-weight: 600;
  }
  .feature-footer-left p.post_no {
    font-size: 14px;
    color: #36364C;
  }
  
  .custom-tags .bg-tag .currency {
    color:#53b2aa;
  }
  
  
  p.cat_title {
    color:#53b2aa;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
  }
  .post-details {
    padding-top: 60px;
  }
  
  .feature_content p.location {
    color: #36364C;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 13px;
  }
  .feature_content p.location i {
    color: #36364C;
  }
  
  
  .filter-cat-layout .product_section_content {
    max-width: 1200px;
    margin: 0 auto;
  }
  .feature_content p {
    margin: 0 0 10px;
  }
  
  .feature_content p i {
    margin-right: 10px;
  }
  
  p.estabish {
    color: #8d8d8d;
  }
  
  .feature_content ul {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;
    min-height: 70px;
    
  }
  
  .feature_content ul li {
    display: flex;
      justify-content: space-between;
      background: #f5f5f5;
      padding: 10px 20px;
      margin: 2px -20px;
      font-size: 12px;
  }
  
  .filter_btn {
    text-align: center;
    padding: 20px;
  }
  
  .filter_btn button {
    font-size: 14px;
    width: 100px;
  }
  span.bg-tag .price {
    text-transform: uppercase;
  }
  
  .search_box {
    display: flex;
    margin-bottom: 20px;
  }
  .with_label input.form-control::placeholder, .year-input::placeholder,
  .with_label.css-1wa3eu0-placeholder,textarea.form-control::placeholder, .with_label .css-1wa3eu0-placeholder{
    color:#8D8D8D;
  }
  .notifaction_item {
    width: 90%;
    background: transparent;
    border: 1px solid #D9D9D9;
    padding: 20px;
    margin: 0 auto 30px;
    border-radius: 4px;
  }
  
  .notifaction_item h3 {
    font-size: 20px;
    text-transform: capitalize;
  }
  
  .notifaction_item p.blue-color {
    color: #0088FF;
  }
  
  .notifaction_item p.date {
    font-size: 14px;
    color: #8D8D8D;
  }
  .search_box button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  h2.img_title {
    color: #202020;
    font-weight: bold;
    
    font-size: 36px;
    
  }
  h2.img_title span{
    /* background-image: url("./images/footer_batch.png"); */
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 80px;
    background-size: 60px;
  }
  .banner:before {
    content: "";
    /* background-image: url("./images/footer_batch.png"); */
    position: absolute;
    width: 100px;
    background-repeat: no-repeat;
    top: 145px;
    background-size: contain;
    height: 44px;
  }
  
  
  .product_section_header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .pagination {
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    text-align: center;
    width: 100%;
    display: block;
  }
  
  .pagination .btn {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    max-width: 48px;;
    padding: 0;
    text-align: center;
    margin-left: 11px;
  }
  .filter-cat-layout .feature_content ul li strong {
    width: 150px;
  }
  
  .filter-cat-layout .feature_content ul li span {
    width: calc(100% - 180px);
  }
  
  
  .pagination button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    margin: 0 3px;
    color: #fff;
    background:#53b2a9;
    font-size: 15px;
  }
  
  button.active {
    background: #49b1a7;
  }
  
  .pagination .btn.disable {
    background: rgb(141 141 141 / 30%);
    border-color: rgb(141 141 141 / 30%);
    color: rgb(32 32 32 / 60%);
    pointer-events: none;
  }
  .feature_content ul li span.net_profit {
    color: #0f3f8c;
  }
  
  
  .latest_business_box {
    width: calc(100% - 320px);
    padding: 80px 30px 0;
    margin-left: 320px;
  }
  .pagination.home.bottom.latest {
    padding-right: 20px;
  }
  .latest_business_wrapper {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;
  
  }
  
  .latest_business_box h2 {
    margin: 0 0 40px;
  }
  .MuiDialog-paper.fullwidth {
    width: 580px;
    border-radius: 20px;
    padding: 30px 40px;
    max-width: 580px;
  }
  .cm-select {
    font-size: 12px;
  }
  .select-search{
    font-size: 12px;
  }
  
  .MuiDialog-paper .closeicon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: #dcdcdc;
    text-align: center;
    line-height: 40px;
    color: #202020;
    border-radius: 50%;
    cursor: pointer;
  }
  .MuiDialog-paper.fullwidth h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-bottom: 0;
  }
  .MuiDialog-paper.fullwidth h2 h2 {
    margin-bottom: 20px;
  }
  
  .MuiDialogTitle-root {
    padding: 0;
  }
  
  .MuiDialogContent-root {
    padding: 0;
  }
  
  p.login-footer-link {
    text-align: right;
  }
  .MuiDialog-paper.fullwidth button.btn.btn-primary {
    border-radius: 6px;
    padding: 10px 20px;
  }
  p.login-footer {
    margin: 0;
  }
  input.form-control,.year-input {
    border: none;
    padding: 14px 15px;
    font-size: 14px;
    height: 50px;
    background: #f0f0f0;
    color: #202020;
    font: 500 14px/18px Montserrat;
    
  }
  .year-picker {
    width: 100%;
  }
  .css-yk16xz-control {
    background: #f0f0f0 !important;
    border: none;
    font-size: 14px;
    min-height: 48px !important;
    border-width: 0px !important;
  }
  .css-g1d714-ValueContainer {
    font-size: 14px;
    min-height: 44px;
  }
  .search_box input.form-control {
    background: #fff;
    height: 40px;
  }
  p.company_type {
    color: #0088ff;
    float: left;
  }
  
  h4.descr_header {
    clear: both;
  }
  
  .feature-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: flex-end
  }
  .otp_input input {
    background: #d9d9d9;
    border: none;
    width: 48px !important;
    height: 48px;
    margin: 0 10px;
    border-radius: 6px;
  }
  
  .otp_input {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .MuiDialog-paper.fullwidth p {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
  .MuiDialog-paper.fullwidth button.btn.btn-primary.signup {
    padding: 10px 20pxpx;
    min-width: 252px;
    margin-bottom: 10px;
  }
  .MuiDialog-paper.fullwidth .MuiDialogTitle-root {
    padding: 0;
  }
  p.login-footer-link {
    padding-bottom: 10px;
  }
  .MuiDialog-paper.fullwidth .MuiDialogContent-root {
    padding: 0;
  }
  .form-control:focus {
    border: 1px solid #ccc;
    box-shadow: none;
    outline: none;
  }
  
  .subscription_box_header {
    background: #49b1a7;
    /* background-image: url("../src/images/green_heder.png"); */
    padding: 20px;
    text-align: center;
    color: #fff;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
  .subscription_box {
    border-radius: 6px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    margin: 0 auto 30px;
  }
  
  .subscription_box_body {
    padding: 30px;
    text-align: center;
  }
  
  .subscription_box_body ul {
    padding: 0;
    margin: 0;
    list-style: none;
    background: #fff;
    min-height: 125px;
  }
  
  .subscription_box_body li {
    margin: 0 0 10px;
  }
  
  .subscription_box_body li i.fa.fa-check {
    color: #14e129;
    margin-right: 10px;
  }
  
  .subscription_box_body li i.fa.fa-times {
    color: #d51515;
    margin-right: 10px;
  }
  
  .subscription_box_header h3 {
    font-size: 15px;
    color: rgb(255 255 255 / 70%);
    font-weight: 300;
  }
  
  .subscription_box_header h3 i {
    margin-right: 10px;
  }
  
  .subscription_box_header h2 {
    font-size: 20px;
  }
  
  .subscription_box_body button.btn {
    background: #49b1a7;
    color: #fff;
    padding: 10px 20px;
  }
  .subscription_box_header p {
    margin: 0;
  }
  
  .subscriptions h2.img_title,
  .center h2.img_title {
    text-align: center;
    background-position: calc(50% - 176px);
    margin: 40px 0 50px;
  }
  
  .subscription_row .col-md-4:nth-child(2n) .subscription_box_header {
    /* background-image: url("./images/blue_header.png"); */
    background-color: #0086ff;
  }
  .subscription_row .col-md-4:nth-child(2n) .subscription_box_body button.btn {
    background: #0086ff;
  }
  .subscription_row .col-md-4:nth-child(3n) .subscription_box_header {
    /* background-image: url("./images/dark_blue.png"); */
    background-color: #0f3f8c;
  }
  .subscription_row .col-md-4:nth-child(3n) .subscription_box_body button.btn {
    background: #0f3f8c;
  }
  
  .blue .subscription_box_body button.btn {
    background: #0086ff;
  }
  
  .page {
    padding: 120px 0 60px;
  }
  .profile_page {
    padding-top: 100px;
  }
  .filter-cat-layout {
      padding-top: 80px;
  }
  .subscriptions {
    padding-top: 20px;
  }
  .page h2 {
    font-weight: 600;
    font-size: 32px;
    color: #202020;
    margin-bottom: 20px;
  }
  
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .page-header button i {
    margin-right: 10px;
  }
  
  .page-header button {
    padding: 10px 40px;
  }
  
  .page p {
    margin: 0 0 15px;
  }
  .subscription_box_header h2 {
    color: #fff;
    font-size: 18px;
    margin: 0 0 10px;
  }
  .with_label label {
    color: #49b1a7;
    font-size: 12px;
    display: inline-block;
    background: #fff !important;
    margin: 0;
    position: absolute;
    left: 25px;
    top: 0px;
    padding: 2px 5px;
    z-index: 1;
  }
  
  .row.with_label:nth-child(2) {
    position: relative;
    z-index: 2;
  }
  .terms_condition label a {
    color: #0088ff;
  }
  .with_label input.form-control,.year-input {
    border: 1px solid #49b1a7;
    background: transparent;
    color: #000;
    height: 48px;
    width: 100%;
  }
  
  .picker-panel {
    z-index: 111;
    margin-top: 48px;
  }
  
  .picker-panel .header {
    line-height: 40px!important;
    text-align: center!important;
    height: 40px!important;
  }
  .with_label .css-yk16xz-control {
    border: 1px solid #49b1a7 !important;
    background: transparent !important;
    min-height: 48px !important;
  }
  
  .react-tel-input .flag-dropdown {
    background: transparent !important;
    border: none !important;
  }
  
  span.user_prefix {
    width: 48px;
    height: 48px;
    background: #80c5bd;
    display: inline-block;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 50%;
    line-height: 48px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .user_content ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .user_content ul li a {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    padding: 10px 44px 10px 0;
    display: block;
    line-height: 21px;
    border-bottom: 1px solid #8d8d8d;
  }
  
  .user_content ul li a i {
    position: absolute;
    right: 15px;
  }
  .visible-mobile {
    display: none;
  }
  .terms label {
    padding-left: 5px;
  }
  
  .terms label a {
    color: #007bff;
  }
  .status_box {
    position: relative;
    margin-bottom: 20px;
    min-height: 10px;
  }
  
  .status_box .status {
    position: absolute;
    right: 0;
    background: #e6ba0d;
    color: #fff;
    padding: 4px 15px;
    border-radius: 25px;
    font-size: 12px;
    text-transform: capitalize;
  }
  span.max_3 {
    color: #231f20;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    font-size: 17px;
  }
  
  .subscription_row {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .terms .input_box {
    display: inline-block;
  }
  
  .feature_content ul li:nth-child(n+3) {
    display: none;
  }
  .feature-footer-right .btn {
    font-size: 20px;
    padding: 3px 8px;
    width: 130px!important;
    color: #f7f6fb;
    background: #53b2aa;
    border-color: #53b2aa;
    margin: 0!important;
    
  }
  
  button.visible-mobile.close_btn {
    position: absolute;
    background: transparent;
    border: none;
    font-size: 23px;
    right: 20px;
    top: 20px;
  }
  
  .succesmodal img {
    margin-bottom: 30px;
  }
  
  .succesmodal h2.succeshead {
    margin-bottom: 20px;
  }
  
  .succesmodal .suc-ccont {
    margin-bottom: 20px;
  }
  .readonly {
    cursor: not-allowed;
  }
  
  .readonly input.form-control {
    pointer-events: none;
  }
  .terms_condition {
    font-size: 14px;
  }
  
  .terms_condition label {
    display: inline-block;
    width: calc(100% - 31px);
    vertical-align: top;
    margin-left: 7px;
  }
  
  .terms_condition input[type="checkbox"] {
    display: inline-block;
  }
  .form-control:focus {
    border-color: #49b1a7;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent;
  }
  
  .react-tel-input .flag-dropdown.open {
    background: transparent;
  }
  .profile_container {
    display: flex;
  }
  
  .profile_sidebar {
    width: 300px;
    background: #f8f8f8;
    position: relative;
    min-height: calc(100vh - 440px);
  }
  
  .profile_sidebar a {
    display: block;
    padding: 14px 50px;
    font-size: 16px;
    color: #8d8d8d;
    position: relative;
    
  }
  .profile_sidebar a:not(.active){
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  button.btn.danger:hover {
    background: #fb2020;
  }
  
  .profile_sidebar:before {
    content: "";
    position: absolute;
    width: 1000%;
    left: -1000%;
    height: 100%;
    top: 0;
    background: inherit;
  }
  
  .profile_sidebar a.active {
    background: #e5f3ff;
    color: #0088ff;
  }
  .profile_content {
    width: calc(100% - 300px);
    padding: 30px;
    position: relative;
  }
  
  .profile_content h2.img_title {
    text-align: center;
    background-position: calc(50% - 89px);
  }
  .profile_sidebar span {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
  .profile_content .form_section {
    max-width: 750px;
    margin: 0 auto;
    padding: 40px 0;
    position: relative;
  }
  .profile_picture {
    text-align: center;
  }
  
  .profile_picture img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
  }
  
  .profile_picture input#pic {
    display: none;
  }
  
  .profile_picture label {
    color: #0088ff;
    cursor: pointer;
    width: 140px !important;
  }
  .user_icon img {
    max-width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  .MuiDialogContent-root {
    overflow: inherit;
  }
  .signup_phone input.form-control {
    padding: 14px 15px 14px 30px;
    font-size: 14px;
    height: 48px;
    background: #f0f0f0;
    width: 100%;
    border: none;
  }
  
  .profile_content .row.with_label:nth-child(3) {
    position: relative;
    z-index: 3;
  }
  a.disable {
    pointer-events: none;
  }
  .profile_section_non_edit label {
    font-weight: bold;
    margin-right: 7px;
  }span.tag {
    background: #e4e4e4;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 12px;
  
    margin-bottom: 5px;
    display: inline-block;
  
  }
  .post_form_row form {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
  textarea.form-control {
    border: 1px solid #49b1a7;
    background: transparent;
    color: #000!important;
    width: 100%;
    height: 100px;
    font-size: 14px;
    padding-top: 15px;
  }
  .file_input_box_wrapper {
    border: 1px solid #49b1a7;
    border-radius: 5px;
    padding: 12px 15px;
    font-size: 12px;
    color: #8D8D8D;
  }
  
  .file_input_box_wrapper h3 {
    font-size: 16px;
    font-weight: 500;
    color: #202020;
  }
  
  .drop_area {
    border: 1px dashed #8D8D8D;
    padding: 20px;
    text-align: center;
    margin: 0 0 6px;
    border-radius: 5px;
    display: block;
  }
  
  .drop_area img {
    display: block;
    margin: 0 auto 4px;
  }
  
  .drop_area span {
    font-size: 12px;
    color: #8D8D8D;
  }
  
  .file_input_box_wrapper input {
    display: none;
  }
  
  .file_input_box_wrapper p.warning span {
    color: #D51515;
  }
  
  .file_input_box_wrapper p.warning {
    color: #202020;
  }
  .pagination.myads {
    position: absolute;
    right: 30px;
    width: auto;
    top: 39px;
  }
  
  .profile_header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-bottom: 30px;
    position: relative;
  }
  
  .profile_header .pagination.home {
    position: absolute;
    right: 0;
  }
  
  
  .file_input_box_wrapper p {
    margin: 0 0 5px;
  }
  
  .file_input_box_wrapper label.btn {
    border: 1px solid #0F3F8C;
    background: rgb(15 63 140 / 10%);
    font-size: 14px;
    padding: 6px 30px;
    margin: 10px 0 0;
    color: #0F3F8C;
  }
  .react-slider__picture{
    min-height: auto!important;
  }
  .product_card {
    background: #F8F8F8;
    margin-top: 40px;
  }
  
  .product_details h2 {
    font-size: 20px;
    color: #202020;
  }
  
  .product_details {
    padding: 20px;
    font-size: 14px;
    color: #8D8D8D;
  }
  
  .product_details p.location {
    color: #0088FF;
    font-size: 14px;
  }
  
  .product_details p.location i {
    margin-right: 10px;
  }
  
  .product_details p {
    margin: 0 0 5px;
  }
  
  .product_details ul {
    padding: 0;
    margin: 20px 0;
    list-style: none;
    clear: both;
    padding-top: 20px;
  }
  
  .product_details ul li strong {
    color: #202020;
    width: 200px;;
    
    max-width: 200px;
    font-weight: 500;
  }
  .product_details ul li > span {
    width: calc(100% - 200px);
  }
  
  .product_details ul li {
    margin: 0 0 10px;
    margin: 0 0 10px;
    display: flex;
    align-items: inherit;
  }
  .MuiDialog-paper.fullwidth button.succsesokay.btn {
    padding: 6px 41px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .product_details .btn.btn-primary {
    font-size: 14px;
    padding: 7px 40px;
  }
  .product_image {
    padding: 15px;
  }
  .product_image {
    padding: 15px;
  }
  
  .product_info h3 {
    font-size: 18px;
    color: #202020;
    font-weight: 600;
    margin-top: 20px;
  }
  
  .product_info {
    padding: 30px 0;
  }
  
  .product_docs ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .contact {
    padding: 100px 15px;
  }
  ul.contact-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  ul.contact-list li {
    margin: 0 0 14px;
    color: #8D8D8D;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  
  .contactForm {
    margin-bottom: 30px;
  }
  
  ul.contact-list li span {
    width: 50%;
  }
  
  ul.contact-list li i {
    margin-right: 10px;
    color: #707070;
    display: inline-block;
    vertical-align: baseline;
  }
  
  
  .contact-box button {
    padding: 8px 40px;
  }
  
  .product_docs ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  
  .product_docs ul li img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  
  .product_docs ul li a {
    color: #0088FF;
    font-size: 14px;
  }
  
  .enquiry_form.row form {
    padding: 40px 0;
    width: 100%;
    max-width: 500px;
  }
  
  .enquiry_form button {
    padding: 6px 35px;
  }
  .product_docs {
    margin-bottom: 20px;
  }
  .enquiry.container {
    padding: 100px 20px;
    
  }
  .viewenquiry {
    padding-top: 100px;
  }
  
  .enquiry_form.row form {
    padding: 20px 0;
    width: 100%;
    
  }
  
  .enquiry_form button {
    padding: 6px 35px;
  }
  .enquiry button.close_btn {
    width: 35px;
    height: 35px;
    border: none;
    background: #DCDCDC;
    font-weight: bold;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 20px;
  }
  
  .enquiry .inner_container {
    max-width: 500px;
    position: relative;
  }
  
  .enquiry_form {
    display: block;
    padding-top: 30px;
  }
  .post_form_row .btn {
    padding: 6px 29px;
  }
  .img_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  .added_images .img_box button {
    background: transparent;
    border: none;
    position: absolute;
    color: #fff;
    background: #103f8c;
    right: -6px;
    top: -6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  
  
  .added_images .img_box {
    width: 70px;
    height:70px;
    display: inline-block;
    margin: 0 10px 10px 0;
    position: relative;
  }
  .post_form_row .row.with_label:nth-child(2) {
    position: relative;
    z-index: 10;
  }
  .post_form_row .row.with_label:nth-child(3) {
    position: relative;
    z-index:9;
  }
  
  .post_form_row .row.with_label:nth-child(4) {
    position: relative;
    z-index:8;
  }
  .product-head {
    padding: 20px 20px 0;
    display: flex;
    justify-content: flex-start;
    min-height: 110px;
    
  }
  .p_btn_box {
    text-align: center;
    margin-top: 20px;
  
  }
  .profile_content .btn-primary.non_edit {
    margin-right: 100px;
  }
  
  .p_btn_box button {
    padding: 7px!important;
    width:180px;
    margin: 0 10px;
  }
  
  
  button.btn.btn-transprent:hover {
    background: #0F3F8C;
    color: #fff;
  }
  
  
  .product_details.inside .feature_image {
    float: left;
    margin-right: 20px;
  }
  .product-head .feature_image {
    float: none;
    margin-right: 10px;
  }
  .react-slider__ul li {
    height: 50px;
  }
  .faq_wrapper .answer {
    display: none;
    font-size: 14px;
    padding: 10px 0;
    line-height: 1.8;
  }
  ul.contact-list li img {
    max-width: 25px;
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-top: -4px;
  }
  ul.contact-list li strong {
    font-weight: 500;
  }
  .profile_section_non_edit .profile_pic {
    padding-right: 128px;
  }
  .faq_wrapper .question {
    font-size: 20px;
    color: #202020;
    font-weight: 500;
    cursor: pointer;
  }
  
  .faq_wrapper .question i {
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .faq-item {
    margin-bottom: 10px;
  }
  .pagination.home {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
  
  .faq-item.active .answer {
    display: block;
  }
  
  .faq-item.active .question {
    color: #0088FF;
  }
  .faq_wrapper {
    margin-top: 30px;
  }
  button.btn.btn-transprent {
    border: 1px solid #0F3F8C;
    padding: 6px 41px;
    color: #0F3F8C;
  }
  button.btn.danger {
    border-color: #fb2020;
    color: #fb2020;
    
  }
  button.btn.btn-transprent.edit {
    margin-right: 15px;
  }
  .file_input_box_wrapper.disable label {
    cursor: not-allowed!important;
    
  }
  .product-filter ul.scroll_list {
    max-height: 400px;
    overflow-y: auto;
  }
  .status_box .status.is_2 {
    background: #56d056;
  }
  
  .status_box .status.is_3 {
    background: red;
  }
  .main-wraper {
    min-height: calc(100vh - 435px);
  }
  .feature-footer-left span.annual {
    color: #103f8c;
    font-weight: 500;
  }
  
  .feature-footer-left {
    font-size: 14px;
    width: calc(100% - 140px);
  }
  
  .feature-footer-left span.profit {
    display: inline-block;
    font-size: 13px;
  }
  .signup_phone input.form-control {
    padding-left: 44px;
  }
  .thankyou {
    text-align: center;
    padding: 50px;
    color: #000;
  }
  .pagination.home.bottom {
    width: 100%;
  }
  .filter-cat-layout .product_section_content {
    padding-bottom: 40px;
  }
  
  .filter-cat-layout .product_section_content .product-item {
    width: 100%;
  position: relative;
    min-height: 350px;
    justify-content: start;
  }
  
  
  
  .filter-cat-layout .feature_content ul li {
    margin-bottom: 20px;
    background: transparent;
  }
  .filter-cat-layout.feature_content p.location {
    margin-bottom: 20px;
  }
  .filter-cat-layout .feature_content {
    padding-top: 0;
  }
  .filter-cat-layout .feature-footer-left {
    max-width: 400px;
    margin-bottom: 20px;
  }
  
  .filter-cat-layout .product-item.has-image {
    padding-left: 290px;
    position: relative;
  }
  
  .filter-cat-layout .product-head .feature_image {
    width: 250px;
    height: 250px;
    position: absolute;
    left: 20px;
  }
  
  
  
  .filter-cat-layout .feature-footer-right button {
    width: 253px!important;
    position: absolute;
    left: 20px;
    bottom: 20px;
    height: 39px;
  }
  .filter-cat-layout .feature_content ul {
    max-width: 400px;
  }
  /* .product-item:hover {
    border: 1px solid #0f3f8c;
  } */
  
  
  .filter-cat-layout .feature_content ul li {
    padding: 0 20px;
    font-size: 14px;
  }
  .no-data-box {
    text-align: center;
    padding: 50px;
    width: 80%;
    margin: 0 auto;
    font-size: 24px;
    color:#202020;
  
  }
  
  .toast-cotent img {
    max-width: 50px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }
  
  .notify-content {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 70px);
  }
  .notify-content h3 {
    font-size: 20px;
    color: #fff;
  }
  .no-add {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color:#202020;;
  }
  .table td a {
    color: #007bff;
  }
  .MuiDialog-paperWidthXs {
    max-width: 500px!important;
    padding: 20px!important;
  } 
  @media (max-width:767px){
    .react-slider__imgZoom {
      display: none;
  }
  .is-ar ul.contact-list {
    margin-top: 20px;
  }
  
  .row.terms  label {
    width: calc(100% - 30px);
    vertical-align: top;
    font-size: 14px;
  }
  .product_details h2 {
    font-size: 16px;
  }
  .product_details ul li strong {
    width: 100%;
    margin-bottom: 6px;
  }
  
  .product_details ul li {
    flex-direction: column;
  }
  
  
  .faq_wrapper .question {
  font-size: 16px;
  }
  .MuiDialog-paper.fullwidth h2 h2 {
    font-size: 18px;
  }
  
  .MuiDialog-paper .closeicon {
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
  }
  
  .MuiDialog-paper.fullwidth p {
    font-size: 14px;
  }
  .footer-copyright img {
    position: relative;
    top: 0;
    display: block;
    margin: 10px auto 0;
  }
  
  
  
  
   .product-item.has-image {
    padding-left: 0;
  }
  
  
  .filter-cat-layout .product-head .feature_image {
    position: relative;
    left: 0;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }
  .product-head {
    min-height: auto;
    margin-bottom: 10px;
  }
  .page h2 {
    font-size: 24px;
  }
  .profile_content .btn-primary.non_edit {
    margin-right: 0;
  }
  
  }
  .terms-box {
    padding: 100px 0 50px;
    
  }
  .terms-box h1{
    text-align: center;
  }
  /* ul.select_lang img {
    width: 0px;
    height: 30px;
    border-radius: 50px;
    margin-right: 5px;
    
  } */
  
  ul.select_lang li {
    display: inline-block;
    font-size: 12px;
    background: #d9d9d9;
    border-radius: 25px;
    margin-left: 2px;
    width: calc(50% - 11px);
    text-align: center;
    padding: 2px 10px;
    margin-right: 2px;
    cursor: pointer;
  }
  ul.select_lang li span {
    display: inline-block;
    vertical-align: middle;
  }
  ul.select_lang {
    margin-bottom: 10px;
  }
  
  .sucess-card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    margin: 30px auto;
    max-width: 500px;
    text-align: center;
  }
  
  .sucess-card  h1 {
    color: #88B04B;
    
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .sucess-card p {
    color: #404F5E;
    font-size: 20px;
    margin: 0 0 20px;
  }
  
  .sucess-card i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 150px;
    margin-left: -15px;
  }
  
  .mark-box {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    background: #9abc6540;
    border-radius: 50%;
    line-height: 150px;
  }
  
  
  
  
  
  .sucess-card.cancel i {
    margin-left: 0;
    color: red;
  }
  
  .cancel .mark-box {
    background: #ff000038;
  }
  
  .sucess-card.cancel h1 {
    color: red;
  }
  
  
  .sucess-card.decline i {
    margin: 0;
    font-size: 70px;
    color: brown;
  }
  
  .sucess-card.decline .mark-box {
    background: #a52a2a8c;
  }
  
  .sucess-card.decline h1 {
    color: brown;
  }
  .notification_btn img {
    width: 100%;
  }
  
  .notification_btn {
    width: 40px;
    position: relative;
  }
  
  .notification_btn span {
    position: absolute;
    right: 0;
    color: #202020;
    font-size: 9px;
    background: #fff;
    border: 1px solid #0088FF;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
    line-height: 14px;
    text-align: center;
  }
  
  .notifaiction_left img {
    width: 30px;
  }
  
  .notifaiction-box {
    position: absolute;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    padding: 20px;
    width: 500px;
    right: 0;
    top: 100%;
    background: #fff;
    margin-top: 27px;
    border-radius: 5px;
  }
  
  .notifaiction-box ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  li.notification_box {
    position: relative;
  }
  
  .notifaiction_left {
    width: 38px;
  }
  
  .notifaiction-box ul li {
    background: #ebf6f5;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
  }
  
  .notifaiction_right h3 {
    font-size: 16px;
    margin: 0;
    font-weight: normal;
  }
  .notifaiction-box ul li h3 {
    font-size: 17px;
    text-transform: capitalize;
  }
  
  .notifaiction-box ul li p {
    margin: 0;
    font-size: 14px;
  }
  
  .notifaiction-box ul li  p.date {
    font-size: 12px;
  }
  
  
  .notification_box button {
    border: none;
    font-size: 14px;
    background: #cfd9e8;
    color: #5676ad;
    padding: 5px 10px;
    width: 161px;
    margin: 0 auto;
    border-radius: 5px;
    display: block;
  }
  .product_details.inside .btn {
    width: 160px;
    padding: 7px 20px;
    border: 1px solid;
    font-size: 14px;
    margin-right: 13px;
    margin-bottom: 10px;
  }
  button.btn.danger:hover {
    background: #fb2020;
  }
  .enquiry_wrapper .table {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(108, 108, 108, 0.1);
  }
  
  .table .thead-light th {
    background: #EFF6F6;
    border: none;
    color: #171F23;
    font-size: 14px;
  }
  
  .table .thead-light {
    border-radius: 8px 8px 0px 0px;
  }
  
  .table td {
    color: rgb(23 31 35 / 60%);
    font-size: 14px;
    
  }
  .enquiry_wrapper img {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
  .enquiry_wrapper tr{
    cursor: pointer;
  }
  
  .MuiDialog-paper.fullwidth.subscription {
    max-width: 580px;
    width: 90%;
  }
  
  .MuiDialog-paper.fullwidth.subscription button.btn {
    padding: 10px 20px;
    height: auto;
    width: 160px;
    margin: 30px 10px 0;
  }
  
  .MuiDialog-paper.fullwidth.subscription img {
    margin: 0 0 20px;
  }
  
  .MuiDialog-paper.fullwidth.subscription h2 {
    margin: 0 0 10px;
  }
  
  .MuiDialog-paper.fullwidth.subscription button.btn.grey {
    background: #D9D9D9;
    color: #202020;
    border-color: #D9D9D9;
  }
  
  
  .login_btn_group {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  p.login-footer.social {
    text-align: center;
    color: #8D8D8D;
  }
  
  .login_options button {
    background: transparent;
    border: none;
    margin: 0 10px;
    box-shadow: 0 3px 12px rgb(0 0 0 / 16%);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .login_options {
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 0 5px;
    margin: 0 -5px;
  }
  p.login-footer.social {
    position: relative;
    margin-top: 20px;
  }
  
  p.login-footer.social:before {content: "";border-bottom: 1px solid #8D8D8D;width: 100%;position: absolute;left: 0;top: 50%;transform: translateY(-50%);}
  
  p.login-footer.social span {
    background: #fff;
    position: relative;
    padding: 0 10px;
    color: #8D8D8D;
  }
  .login_options button img {
    width: 100%;
    
  }
  
  
  .terms_condition .input_box {
    display: inline-block;
  }
  .picker_box i {
    position: absolute;
    right: 10px;
    top: 16px;
  }
  
  .picker_box {
    position: relative;
  }
  
  /* Styles for desktop */
@media (min-width: 768px) {
  /* Uncommented styles for desktop */
  .react-datepicker-popper {
    width: 70%;
  }

  .react-datepicker-wrapper {
    width: 70%;
  }

  .react-datepicker__month-container {
    width: 70%;
  }

  .react-datepicker {
    width: 47%;
  }
}

/* Styles for mobile */
@media (max-width: 767px) {
  /* Commented styles for mobile */
  .react-datepicker-popper {
    width: 90%;
  }

  .react-datepicker-wrapper {
    width: 90%;
  }

  .react-datepicker__month-container {
    width: 90%;
  }

  .react-datepicker {
    width: 75%;
  }
}

  .profile_sidebar img {
    max-width: 20px;
  }
  .react-datepicker__year .react-datepicker__year-text {
    width: 70%;
  }
  
  .react-datepicker__year-wrapper {
    max-width: 70%;
  }
  .filter-cat-layout .product-item:not(.has-image) .feature-footer-right button {
    position: relative;
    left: 0;
    bottom: 0;
    margin-top: 10px;
  }
  .filter-cat-layout .product_section_content .product-item:not(.has-image){
    min-height: auto;
  }
    
  
  
  
  .filter-cat-layout .product-item:not(.has-image) .feature-footer {
    flex-direction: column;
    align-items: baseline;
    padding-top: 0;
  
  }
  .profile_content .btn {
    padding: 7px!important;
    width: 120px;
    margin: 0 10px;
  }
  .py-2.readonly .react-tel-input {
      
      cursor: not-allowed;
  }
  
  .py-2.readonly .selected-flag {
      pointer-events: none;
      z-index: 0;
  }
  
  .py-2.readonly .flag-dropdown {
      pointer-events: none;
  }
  .MuiDialogContent-root{
    overflow-x: hidden!important;
  }
  
  h2.img_title.non_edit {
    margin-left: -202px;
  }
  .filter-cat-layout .estabish.status_also {
    min-height: auto;
  }
  .grey .subscription_box_header{
    /* background-image: url('../src/images/grey_bg.png')!important; */
    
    background-color: #d9d9d9!important;
    color: #202020;
  }
  .grey .subscription_box_header h3,
  .grey .subscription_box_header h2{
    color: #202020!important;
  }
  .grey .subscription_box_body button.btn{
    background-color: #D9D9D9!important;
    color:  #202020!important;
  
  }
  .succsesokay {
      min-width: 200px;
      text-align: center;
      padding: 9px!important;
  }
  .product_section_content .no-data-box {
    padding-top: 300px;
  }
  .input_box{
    position: relative;
  }
  .input_box i {
    position: absolute;
    right: 12px;
    top: 17px;
    color: #3a3131e0;
  }
  .globe-banner h1 {
    font-size: 42px;
    color: #4ab1a8;
  }
  
  .globe-banner p.lead {
    color: #202020;
    font-weight: bold;
  }
  
  .globe-banner {
    padding: 100px 0 50px;
    /* background-image: url('./images/bg_image.png'); */
    background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      
  }
  .globe-banner:before {
    content: "";
    /* background-image: url('./images/globe_back.png'); */
    position: absolute;
    width: 66%;
    right: 0;
    background-size: cover;
    background-position: center center;
    height: 100%;
    top:0
  }
  
  
  .globe-image {
    /* background-image: url('./images/globe.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 30vw;
    background-position: center;
  }
  .globe-banner .row {
    align-items: center;
  }
  
  .loc_box {
    min-height: 20px;
  }
  
  .loc_box .estabish.status_also {
    margin: 0;
  }
  .feature-footer-left p img {
    margin-right: 5px;
    vertical-align: middle;
  }
  .subscription_box_header {
    background: #EBF6F5;
    color: #000000;
    text-align: center;
  
  
  }
  
  .subscription_box_header h2 {
    color: #000000;
    font-size: 22px;
  }
  
  .subscription_box_header p {
    color: #49B1A7;
    font-size: 17px;
  }
  
  .subscription_box_header h3 {
    color: rgb(32 32 32 / 70%);
    margin-bottom: 14px;
  }
  
  
  
  .subscription_box button.btn.btn-block i {
    color: #D51515;
    margin-right: 10px;
  }
  
  
  
  .subscription_box {
    box-shadow: 0px 5px 26px rgba(73, 177, 167, 0.12);
  }
  @media (max-width:1450px) {
    .feature-footer-left p.post_no {
      font-size: 14px;
      color: #36364C;
  }
  
  .product-filter {
      width: 280px;
  }
  
  .product_section_content {
      width: calc(100%  - 280px);
      
  }
  
  .feature_content h3, .product-head h3 {
      font-size: 16px;
      text-transform: capitalize;
  }
  
  span.max_3 {
      font-size: 14px;
  }
  
  .custom-tags .bg-tag {
      font-size: 9px;
  }
  
  span.cm-sector {
      font-size: 9px;
  }
  
  .feature-footer-left p.post_no {
      font-size: 12px;
      padding: 0;
      margin-top: 5px;
  }
  
  .feature-footer-right .btn {
      font-size: 16px;
      width: 120px;
  }
  
  .feature_content {
      padding: 10px;
  }
  
  .feature-footer {
      padding: 10px;
  }
  
  .product-head {
      padding: 10px 10px 0;
  }
  
  .custom-tags .t-item {
      font-size: 9px;
  }
  .filter-cat-layout .feature_content {
    padding-left: 20px;
  }
  
  .filter-cat-layout .feature-footer {
    padding-left: 20px;
  }
  
  .filter-cat-layout .product-head {
    padding-left: 20px;
  }
  
  
    
  }
  
  .col-md-12.contact-box {
    margin-top: 40px;
  }
  
  .col-md-12.contact-box h2.img_title {
    margin-bottom: 60px;
  }
  .filter-cat-layout .product-head {
    min-height: auto;
  }
  
  ul.select_lang {
    position: fixed;
    right: 7px;
    top: 50%;
    z-index: 11;
    margin-top: -70px;
    z-index: 999;
  }
  
  ul.select_lang li {
    display: block;
    width: auto;
    height: auto;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 0;
    overflow: hidden;
    margin-bottom: 23px;
    border: 1px solid #ccc;
  }
  
  ul.select_lang li span {
    display: block;
    position: absolute;
    right: 0px;
    left: 0;
    text-align: center;
  }
  
  ul.select_lang li img {
    width: 80px;
    height: 50px;
  }
  
  ul.select_lang li.active {
    border-width: 2px;
  }
  
  .is-ar .globe-banner .row {
    flex-direction: row-reverse;
    text-align: right;
  }
  .is-ar .search_options {
    text-align: right;
  }
  
  .is-ar .product-filter ul li input {
    left: auto;
    right: 0;
  }
  .is-ar .page-header {
    flex-direction: row-reverse;
  }
  .is-ar .main-content p {
    text-align: right!important;
  }
  
  .is-ar .product-filter ul li {
    padding-right: 22px;
    padding-left: 0;
  }
  .is-ar .main-content p {
    text-align: right!important;
  }
  
  .is-ar .post_form_row.row {
    text-align: right;
  }
  
  .is-ar .post_form_row.row input.form-control,
  .is-ar .post_form_row.row textarea {
    text-align: right;
  }
  
  .is-ar .with_label label {
    left: auto;
    right: 25px;
  }
  .is-ar .with_label input.form-control,
  .is-ar  .year-input {
    text-align: right;
  }
  .is-ar.MuiDialog-paper.fullwidth h2 h2 {
    text-align: right;
    padding-right: 20px;
  }
  .is-ar.MuiDialog-paper.fullwidth p {
    text-align: right;
  }
  
  
  
  
  .is-ar .input_box.password input.form-control {
    padding-right: 42px;
    text-align: right;
  }
  .is-ar .profile_sidebar a {
    flex-direction: row-reverse;
    display: flex;
  }
  
  .is-ar .profile_sidebar img {
    margin-left: 10px;
  }
  
  .is-ar .terms-box p {
    text-align: right!important;
  }
  
  .is-ar .faq-item {
    text-align: right;
  }
  
  .is-ar .faq-item.active .question {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .is-ar .faq_wrapper .question i {
    margin-left: 10px;
    margin-right: 0;
  }
  .is-ar .product-item {
    text-align: right;
  }
  
  .is-ar .product-head {
    justify-content: flex-end;
    text-align: right;
  }
  .is-ar .react-datepicker-wrapper input {
    padding-right: 40px;
  }
  
  .is-ar .product-head h3 {
    padding-right: 0;
  }
  
  .is-ar .feature-footer {
    flex-direction: row-reverse;
  }
  
  
  .is-ar .contact-box .row {
    flex-direction: row-reverse;
  }
  
  .is-ar .contact-box .row button {
    float: right;
  }
  
  .is-ar textarea.form-control {
    text-align: right;
  }
  
  .is-ar ul.contact-list li {
    flex-direction: row-reverse;
  }
  
  .is-ar ul.contact-list li strong img {
    float: right;
    margin-left: 10px;
  }
  .is-ar .col-md-12.contact-box h2.img_title {
    text-align: right;
  }
  .is-ar .filter-cat-layout .product-item.has-image {
    padding-left: 0;
    padding-right: 290px;
  }
  
  .is-ar .filter-cat-layout .product-head .feature_image {
    left: auto;
    right: 20px;
    margin-right: 0;
  }
  
  .is-ar .filter-cat-layout .feature-footer-right button,
  .is-ar .filter-cat-layout .product-item:not(.has-image) .feature-footer-right button {
    left: auto;
    right: 20px;
    position: absolute;
    bottom: 20px;
  }
  
  
  
  
  .is-ar .product_card .row {
      flex-direction: row-reverse;
      text-align: right;
  }
  .is-ar .product_info {
    text-align: right;
  }
  
  .is-ar .css-1wa3eu0-placeholder {
    right: 0;
  }
  
  .is-ar .css-1uccc91-singleValue {
    left: auto;
    right: 0;
  }
  
  
  .is-ar .with_label label{
  
  }
  
  
  
  @media (max-width:1199px) {
    .product-item {
      width: calc(50% - 20px);
  }
  
  
  }
  @media (max-width: 991px) {
    .globe-banner .row {
      flex-direction: column-reverse;
  }
  .is-ar footer {
    text-align: right;
  }
  
  .is-ar ul.footer_social img {
    float: right;
    margin-left: 10px;
    margin-right: 0;
  }
  .is-ar .globe-banner .row {
    flex-direction: column-reverse;
  }
  .user_btns {
    position: relative;
    top: 11px;
  }
  li.user_btns {
    width: 129px;
    top: -2px;
    right: -47px;
  }
  
  .visible-mobile.user_btns {
    top: 10px;
  }
  .is_login.visible-mobile.user_btns {
    top: 0;
  }
  
  .is_login.visible-mobile.user_btns .notification_btn {
    right: 0px;
    top: 9px;
  }
  .is_login  .notifaiction-box {
    left: auto;
    right: -30px;
  }
  
  .is_login li.user_btns {
    right: 10px;
    top: 0;
  }
  .is-ar .content-txt {
    margin-bottom: 20px;
  }
  
  .notifaiction-box {
    min-width: 300px;
    right: -31px;
    left: auto;
  }
  
  .notification_btn {
    right: -28px;
    top: 7px;
  }
  .row.terms  label {
    width: calc(100% - 30px);
    vertical-align: top;
    font-size: 14px;
  }
  
  p.custom-tags.two_col > span:first-child {
    width: 100%;
  }
  
  p.custom-tags.two_col > span {
    width: 100%;
  }
  
  p.custom-tags.two_col {
    flex-direction: column;
  }
  .enquiry_wrapper {
    overflow-y: auto;
  }
  .enquiry_wrapper .table {
    min-width: 600px;
  }
  .MuiDialog-paper.fullwidth.subscription button.btn {
    
    margin: 10px 10px 0;
  }
  .user_icon img {
    width: 30px;
    height: 30px;
    margin-right: 3px;
  }
  
  .notification_btn {
    width: 30px;
    position: absolute;
    right: 70px;
    top: 12px;
  }
  .notifaiction-box {
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    top: 35px;
  }
  
  li.notification_box {
    position: static;
  }
  
  
  .menu_wrapper li.notification_box {
    display: none;
  }
  .product_details ul li strong {
    width: auto;
    padding-right: 14px;
  }
  
  .product_details ul li > span {
    width: auto;
  }
  
  .globe-banner .row > div {
      max-width: 100%;
      width: 100%;
  }
  
  .globe-banner .content-txt {
      padding-top: 33px;
  }
  
  .globe-image {
      min-height: 65vw;
      margin-bottom: 27px;
  }
    .login_options {
      flex-direction: column;
  }
  h2.img_title.non_edit{
    margin-left: 0;
  }
  
  .login_options button {
      max-width: 140px;
      margin: 5px auto;
  }
    .filter-cat-layout .product-item:not(.has-image){
      padding-bottom: 0;
    }
    .filter-cat-layout .product-item:not(.has-image) .feature-footer-right button {
      width: 100%;
  }
    .btn-primary.non_edit {
    margin-right: 0;
  }
  
  .profile_section_non_edit .profile_pic {
    padding-right: 0;
  }
    .product-filter {
      position: fixed;
      width: 100%;
      left: -100%;
      transition: all 0.3s ease;
      z-index: 999;
      top:0;
      width: 100%;
      overflow-y: auto;
      height: 100vh
    }
    .filter-cat-layout .product-item.has-image {
      padding-left: 0;
  }
  
  
  
  .filter-cat-layout .feature-footer-right button {
      width: calc(100% - 40px)!important;
  }
  
  .filter-cat-layout .product-item {
      padding-bottom: 60px;
  }
    footer h3 {
      margin-bottom: 15px;
  }
    .product-filter.show {
      left: 0;
    }
    .text-center.filter-head {
      margin-bottom: 10px;
  }
    
    .main-wraper {
      overflow: hidden;
    }
    .visible-mobile {
      display: block;
    }
    .visible-desktop {
      display: none !important;
    }
    .menu_wrapper {
      position: absolute;
      width: 100%;
      left: 0;
      top: 60px;
      background: #fff;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 8%);
      display: none;
      z-index: 11;
    }
    .menu_wrapper.active {
      display: block;
    }
  
    button.mobile_triger {
      background: transparent;
      border: 1px solid #103f8c;
      color: #103f8c;
      width: 40px;
      height: 40px;
      font-size: 23px;
      position: absolute;
      right: 23px;
    }
    li.user_btns {
      list-style: none;
      position: absolute;
      width: 100px;
      top: 11px;
      right: 54px;
    }
  
    .header {
      min-height: 59px;
      padding-top: 16px;
      padding-bottom: 15px;
    }
    ul.header_menu > li {
      display: block;
    }
  
    ul.header_menu > li > a {
      padding: 10px;
    }
  
    .header_menu .animation {
      display: none;
    }
  
    ul.header_menu > li > ul {
      position: relative;
      opacity: 1;
      visibility: visible;
      box-shadow: none;
      width: 100%;
      padding: 0;
    }
  
    ul.header_menu > li > ul li > a {
      border: none;
      padding-left: 30px;
    }
    ul.header_menu.container {
      margin: 0 auto;
    }
    .banner {
      padding: 280px 0 20px;
      background-size: 300px;
      background-position: top center;
  }
  
    h1 {
      font-size: 27px;
      line-height: 1.3;
    }
  
    .product-filter {
      width: 100%;
    }
  
    .product-section {
      display: block;
    }
  
    .product_section_content {
      width: 100%;
    }
  
    .product-item {
      width: calc(100% - 20px);
    }
  
    .latest_business h2 {
      margin: 0 0 30px;
    }
  
    .latest_business_box {
      max-width: 100%;
      margin-left: 0;
    }
    .latest_business_wrapper{
      display: block;
    }
    .page-header {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
    .post_form_row.row {
      padding: 0 31px;
  }
  .post_form_row.row {
    padding: 0 31px;
  }
  
  .profile_sidebar {
    width: 50px;
  }
  
  .profile_sidebar a span {
    display: none;
  }
  
  .profile_sidebar a {
    padding: 10px;
  }
  .profile_content {
    width: calc(100% - 50px);
  }
  .profile_content {
    width: calc(100% - 50px);
    padding: 15px;
  }
  
  .p_btn_box button {
    width: 130px;
    font-size: 12px;
    margin: 0 0 10px;
    
  }
  
  h2.img_title span {
    font-size: 22px;
  }
  .product_section_content {
    padding: 0 15px;
  }
  .banner:before {
    display: none;
  }
  .MuiDialog-paper.fullwidth {
    padding: 20px;
    margin: 0;
    max-width: 90%;
  }
  .login_options button img {
    width: 100%;
    margin: 0;
  }
  .login_btn_group button.btn {
    font-size: 14px;
    padding: 6px;
  }
  .feature-footer-left {
    width: 100%;
    margin-bottom: 15px;
  }
  
  .feature-footer {
    display: block;
  }
  
  .latest_business_box {
    width: 100%;
  }
  }
  .MuiDialog-paper.fullwidth h2.registe-heding {
    font-weight: normal;
    max-width: 316px;
  }
  .globe-banner .btn {
    background: transparent;
    color: #0f3f8c;
    padding: 13px 40px;
    width: 300px;
    font-size: 18px;
  }
  
  .globe-banner .btn:hover {
    background: #0f3f8c;
    color: #fff;
  }
  
  
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  /* max-width: 700px !important; */
}


.react-draggable {
    border: 1px dashed #BEBEBE;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    padding: 50px 0;
    cursor: grab;
}



::-webkit-scrollbar {
  width: 11px;    
  height: 0px;
}

  
  /* Track */
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff; 
  border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
  background: #363636;
  border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  background: #363636;
  }


  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
  }

  .checkboxAlign {
    display: flex;
    align-items: center;
  }
